import { ApiClient } from '@/lib/mdip/api-client'
import { ApiResponseType } from '@/lib/mdip/types'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'

const url = '/api/v1/auth/oath-stats' as const
type OathStatsResponse = ApiResponseType<typeof url, 'get'>
type UseDatasetsQueryProps = {
  config?: Omit<
    UseQueryOptions<OathStatsResponse, Error, OathStatsResponse>,
    'queryKey'
  >
}

const CURRENT_USER_QUERY_KEY = 'oath_stats'

export default function useOathStatsQuery({ config }: UseDatasetsQueryProps) {
  const { data: session } = useSession()
  return useQuery({
    gcTime: 1000 * 60,
    queryKey: [CURRENT_USER_QUERY_KEY],
    queryFn: async () =>
      ApiClient({
        url,
        method: 'get',
        options: { cache: 'no-store' },
      }),
    enabled: !!session?.user,
    ...config,
  })
}
