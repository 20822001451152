import { ApiClient } from '@/lib/mdip/api-client'
import { useMutation } from '@tanstack/react-query'

const url = '/api/v1/auth/users/current/actions/accept-oath' as const

export const useAcceptOath = () => {
  return useMutation({
    mutationFn: async () => {
      return ApiClient({
        url,
        method: 'post',
      })
    },
  })
}
