'use client'
import { PageEthicalOathBadge } from '@/tina/types'
import { tinaField } from 'tinacms/dist/react'
import Link from 'next/link'
import { ArrowOutward, Button, Skeleton } from '@/lib/mui'
import { CMSLink, formatNumbers } from '@/lib/utils'
import styles from './badge.module.css'
import Image from 'next/image'
import badgeIcon from '@public/assets/illustrations/medium/ethical_badge.svg'
import parse from 'html-react-parser'
import { useSession } from 'next-auth/react'
import { MarkdownLocal } from '@/lib/content/utils'
import useOathStatsQuery from '@/hooks/queries/useOathStatsQuery'
import IconHonour from '@public/assets/illustrations/small/ethical_badge_mini.svg'
import { internalNav } from '@/constants'
import classNames from 'classnames'
import { FC, useEffect } from 'react'
import { useGlobalDialogs } from '@/components/GlobalDialogs'
import useCurrentUserQuery from '@/hooks/queries/useCurrentUserQuery'

export const AvatarBadge: FC<{ className?: string }> = ({ className }) => {
  const { data: session } = useSession()

  return (
    <div className={classNames(styles.badge, className)}>
      <div className="relative flex items-center">
        <Image src={badgeIcon} alt="badge" width={150} height={150} />
        <div className="absolute -right-5 flex -rotate-90 items-center justify-center">
          <p className="text-sm text-grey">Project Hafnia</p>
        </div>
      </div>
      <div className="flex flex-col gap-1.5">
        <span className="text-left text-[20px] font-bold">
          {session ? (
            <>
              {session.user.name} {session.user.familyName}
            </>
          ) : (
            <Skeleton height={30} width={100} variant="rounded" />
          )}
        </span>
        <span className="text-start text-sm text-neutral-500">
          Signed Project Hafnia Ethical Oath
        </span>
      </div>
    </div>
  )
}

const LoadingPage = () => {
  return (
    <>
      <div className="ml-10 mt-10">
        <div className="relative grid grid-cols-[max-content_1fr] gap-x-15 gap-y-4">
          <div className="pl-5; relative flex h-75 w-60 flex-col rounded-br-[80px] rounded-tl-[80px] border-[10px] border-solid border-milestone-blue bg-white">
            <Skeleton
              variant="rectangular"
              height={400}
              className="rounded-br-[80px] rounded-tl-[80px]"
            />
          </div>
          <div className="flex flex-col text-left">
            <Skeleton variant="rectangular" height={300} />
          </div>
        </div>
      </div>
    </>
  )
}

export function EthicalOathSignBadge(props: {
  pageData: PageEthicalOathBadge
  disableRedirect?: boolean
}) {
  const pageData = props.pageData
  const { data: oathCounter, isLoading: isLoadingOathCounter } =
    useOathStatsQuery({})
  const { data: user, isLoading: isLoadingUser } = useCurrentUserQuery({})
  const { data: session } = useSession()
  const {
    actions: { setEthicalOath },
  } = useGlobalDialogs()
  useEffect(() => {
    if (!isLoadingUser && user && !user?.is_oath_accepted) {
      setEthicalOath({ open: true })
    }
  }, [user?.is_oath_accepted, setEthicalOath, isLoadingUser, user])

  return isLoadingUser || !user?.is_oath_accepted || isLoadingOathCounter ? (
    <LoadingPage />
  ) : (
    <div className="mb-10 flex flex-col gap-10">
      <div className="relative flex h-auto w-full flex-col items-start gap-2.5 rounded-lg bg-white p-6 pt-8">
        <div className="absolute -top-[32px] left-10 flex h-[60px] w-[60px] items-center justify-center rounded-full bg-neutral-100">
          <Image
            src={IconHonour}
            alt="honour"
            width={48}
            height={48}
            className="flex-shrink-0 text-white"
          />
        </div>
        <div className="text-p3 leading-h5">
          When using Project Hafnia, I{' '}
          {session ? (
            <span className="font-bold">
              {session.user.name} {session.user.familyName}
            </span>
          ) : (
            <Skeleton
              height={20}
              width={100}
              variant="rounded"
              className="mx-1 inline-block"
            />
          )}
          , pledge to develop technology to benefit individuals, society and the
          environment.
        </div>
        <div className="flex w-full flex-col items-center justify-between sm:flex-row">
          <span
            className="text-sm"
            data-tina-field={tinaField(pageData, 'subtitle')}
          >
            <MarkdownLocal
              content={pageData.subtitle}
              replaceData={{
                counter: oathCounter?.users_accepted
                  ? formatNumbers(oathCounter?.users_accepted)
                  : 0,
              }}
            />
          </span>
          <Link
            className="sm:ml-auto"
            href={internalNav.ethics}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="text" color="primary" endIcon={<ArrowOutward />}>
              Our Ethical Principles
            </Button>
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-6 rounded-xl bg-tranparent-grey-5 p-6 sm:p-8 md:flex-row md:items-center md:gap-12 md:p-10 lg:p-15">
        <AvatarBadge className="self-center" />

        <div className="flex flex-col items-start gap-5">
          <div className="flex flex-col gap-2.5 text-start md:text-center">
            <h1
              className="text-h3 font-bold leading-h3"
              data-tina-field={tinaField(pageData, 'title1')}
            >
              <MarkdownLocal content={pageData.title1} />
            </h1>
            <div className="" data-tina-field={tinaField(pageData, 'title2')}>
              <MarkdownLocal content={pageData.title2} />
            </div>
          </div>
          <Link {...CMSLink(pageData.button)}>
            <Button
              color="primary"
              data-tina-field={tinaField(pageData.button)}
            >
              {parse(pageData.button.text)}
            </Button>
          </Link>
          <div className="flex flex-col items-start gap-2.5">
            <span className="font-bold">What your badge represents:</span>
            <div className="flex flex-col items-start">
              {pageData.badgeBenefits.map((benefit, index) => (
                <span key={`badge-benefit-${index}`} className="text-md">
                  {`⭐️ ${benefit}`}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
